import Turbolinks from 'turbolinks'

export function turbolinksScrollPersistenceInit() {
  document.addEventListener('turbolinks:load', () => {
    let persistScroll = false
    const elements = document.querySelectorAll('[data-turbolinks-scroll]')

    elements.forEach(function (element) {
      element.addEventListener('click', () => {
        persistScroll = true
      })

      element.addEventListener('submit', () => {
        persistScroll = true
      })
    })

    if (elements.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Turbolinks.Visit.prototype.loadResponse = function () {
        return null != this.response ? this.render(() => {
          let t, e
          let scroller = this.performScroll

          if (persistScroll) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            scroller = function () { }
          }
          return this.cacheSnapshot(), this.request.failed ? (this.controller.render({
            error: this.response
          }, scroller), 'function' == typeof (t = this.adapter).visitRendered && t.visitRendered(this), this.fail()) : (this.controller.render({
            snapshot: this.response
          }, scroller), 'function' == typeof (e = this.adapter).visitRendered && e.visitRendered(this), this.complete())
        }) : void 0
      }
    }
  })
}