export function emailCaptureInit() {
  $(() => {
    $('[data-email-capture]').each((_, input) => {
      const $form = $(input)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function handleResponse(event: any, status: any, xhr: any) {
        let json
        try {
          if (event.detail) {
            json = JSON.parse(event.detail[2].response)
            status = event.detail[1]
          } else {
            json = xhr.responseJSON
          }
        } catch (ex) {
          json = {
            errors: 'An unexpected error occurred!  Please try again later.',
          }
        }

        $form.append(
          $('<div>').text(json.errors).addClass('errors'),
        )

        if (!json.errors) {
          $form.find('input[type="email"]').val('')
          $form.find('input[type="email"]').replaceWith(`<input id="success" type="email" disabled="" value="${json.message}" />`)
          $form.find('input[type="submit"]').remove()
        }

        if (json.errors) {
          $form.find('input[type="email"], button[type="submit"]').addClass('error-border')
        }
      }

      $form.on('ajax:success', (event, data, status, xhr) => {
        handleResponse(event, status, xhr)
      })

      $form.on('ajax:error', (event, xhr, status) => {
        handleResponse(event, status, xhr)
      })

      $form.on('ajax:before', (_event, _xhr, _status) => {
        $form.find('.errors').remove()
      })
    })
  })
}
