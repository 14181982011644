export function scrollProgress() {
  $(window).on('scroll', () => {
    const scroll = document.documentElement.scrollTop
    const height = document.body.scrollHeight - window.innerHeight
    const scrollPercent = (scroll / height) * 100
    const $scrollBar = document.getElementById('bar') as HTMLElement | null
    if (!$scrollBar) {
      return
    }

    $scrollBar.style.width = scrollPercent + '%'
  })
}