export function shareInit() {
  $(() => {
    $('[data-share=copy').each((_,input) => {
      const $link = $(input)
      
      $link.on('click', async () => {
        const copiedLink = input.getAttribute('data-share-href')

        if (copiedLink) {
          await navigator.clipboard.writeText(copiedLink)
            .then(() => {
              console.log('succesfully copied!')
              $link.find('span').text('Link Copied!')
            })
            .catch((err) => console.error(err))
        } else {
          console.log('Element does not have "data-share-href" attribute set.')
        }
      })
    })
  })
}