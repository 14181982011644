
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    grecaptcha?: { reset: () => void, execute: () => void },
    submitInvisibleRecaptchaForm: () => void
    Rails: { fire: (element: HTMLFormElement, name: string) => void }
  }
}

function submitInvisibleRecaptchaForm() {
  const form = document.querySelector('[data-contact-form]') as HTMLFormElement
  const $alert = $('.alert', form)

  if (!form.checkValidity()) {
    $alert.text('Please ensure all form fields are filled out.').addClass('alert-danger').show()
    window.grecaptcha?.reset()
    return
  }
  
  $alert.removeClass('alert-danger')

  window.Rails.fire(form, 'submit')
}

function validate(event: Event) {
  event.preventDefault()

  if(!window.grecaptcha) {
    return
  } else {
    window.grecaptcha.execute()
  }
}

export function contactFormInit() {
  const errorMessage = 'Sorry, something went wrong. Instead, please contact our main office at 214.361.2275.'

  window.submitInvisibleRecaptchaForm = submitInvisibleRecaptchaForm

  const button = document.getElementById('submit-btn') as HTMLButtonElement
  
  if(button) {
    button.onclick = validate
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleResponse($form: any, event: any, status: any, xhr: any) {
    // Handle backwards compat for [rails/jquery]-ujs ajax callbacks
    let json
    try {
      if (event.detail) {
        json = JSON.parse(event.detail[2].response)
        status = event.detail[1]
      } else {
        json = xhr.responseJSON
      }
    } catch(ex) {
      status = 'error'
      json = {}
    }

    const $alert = $('.alert', $form)
    if (status == 'OK' || status == 'success') {
      $('input:visible, textarea', $form).val('')
      $alert.text(json.message)
      if (typeof window.grecaptcha != 'undefined') {
        try {
          window.grecaptcha.reset()
        } catch(ex) {
          console.error(ex)
        }
      }
    } else if (json.message) {
      $alert.addClass('alert-danger').text(json.message)
    } else {
      $alert.addClass('alert-danger').text(errorMessage)
    }

    $alert.show()
  }

  $('[data-contact-form]').each(function(_, input) {
    const $form = $(input)

    $form.on('ajax:success', function(event, data, status, xhr) {
      try {
        handleResponse($form, event, status, xhr)
      } catch(ex) {
        alert(errorMessage)
        throw ex
      }
    })

    $form.on('ajax:error', function(event, xhr, status) {
      try {
        handleResponse($form, event, status, xhr)
      } catch(ex) {
        alert(errorMessage)
        throw ex
      }
    })

    $form.on('submit', function() {
      let params = new URL(window.location.toString()).searchParams

      $form.append(`<input type="hidden" name="x-location" id="x-location" value="${window.location.toString()}">`)

      // TODO: move into the gem
      if(document.referrer && document.referrer.length > 0) {
        $form.append(`<input type="hidden" name="x-referrer" id="x-referrer" value="${document.referrer}">`)
      }

      // try getting the search query from either this page or the referrer page
      // example: search -> event -> contact
      params = new URL(window.location.toString()).searchParams
      let searchQuery = params.get('query') || params.get('q')
      if ((!searchQuery || searchQuery.length == 0) &&
          (document.referrer && document.referrer.length > 0)) {
        params = new URL(document.referrer).searchParams
        searchQuery = params.get('query') || params.get('q')
      }

      if (searchQuery && searchQuery.length > 0) {
        $form.append(`<input type="hidden" name="x-search-query" id="x-search-query" value="${searchQuery}">`)
      }
      
    })
  })

  $(document).on('click', '[data-modal-contact]', function(event) {
    const $link = $(event.currentTarget)
    const $form = $('form[data-contact-form]', $link.data('target'))
    const $data = $link.data('modal-contact')

    $('#email_object_type', $form).val($data.email_object_type)
    $('#email_object_id', $form).val($data.email_object_id)
  })
}
