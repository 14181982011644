import * as bootstrap from 'bootstrap'
import { observeScriptureIntersection } from '../lib/observe-scripture-intersection'

export function frontMatterInit() {
  const frontMatterButtons = document.querySelectorAll('.front-matter__button')
  const frontMatterOffcanvases = document.querySelectorAll('[id^="front-matter-offcanvas-"]')

  Array.from(frontMatterOffcanvases).forEach((offcanvas) => {
    const bsOffcanvas = new bootstrap.Offcanvas(offcanvas as HTMLElement)
    const frontMatterDisplayButton = document.querySelector('.front-matter-button.d-flex')
    frontMatterDisplayButton?.addEventListener('click', evt => {
      const target: HTMLElement = evt.target as HTMLElement
      target.classList.toggle('close')
    })

    const closeButtons = document.querySelectorAll('.front-matter-canvas > .close')

    closeButtons.forEach((button) => {
      button.addEventListener('click', evt => {
        const target: HTMLElement = evt.target as HTMLElement
        if (target && target.parentElement) {
          const parentEl = target.parentElement.parentElement as HTMLElement

          parentEl.classList.add('hiding')
          offcanvas?.classList.remove('darken')

          setTimeout(() => {
            parentEl.classList.remove('active')
            parentEl.classList.remove('hiding')
            frontMatterDisplayButton?.classList.remove('close')
            bsOffcanvas.hide()
          }, 250)
        }
      })
    })

    const backButtons = document.querySelectorAll('.front-matter-canvas > .back')
    backButtons.forEach(button => {
      button.addEventListener('click', evt => {
        const target: HTMLElement = evt.target as HTMLElement
        if (target && target.parentElement) {
          const parentEl = target.parentElement.parentElement as HTMLElement
          parentEl.classList.add('hiding')
          offcanvas?.classList.remove('darken')

          setTimeout(() => {
            parentEl.classList.remove('active')
            parentEl.classList.remove('hiding')

            offcanvas?.setAttribute('data-last-resource', '')
          }, 250)

        }
      })
    })

    offcanvas?.addEventListener('shown.bs.offcanvas', () => {
      const lastResource = offcanvas.getAttribute('data-last-resource')

      if (lastResource) {
        const el = document.getElementById(lastResource)

        if (el) {
          el.classList.add('showing')
          el.classList.add('active')

          setTimeout(() => {
            offcanvas.classList.add('darken')
            el.classList.remove('showing')
          }, 100)
        }
      }
    })

    if (!frontMatterButtons) {
      return
    }

    frontMatterButtons.forEach((button) => {
      button.addEventListener('click', (evt) => {
        const target: HTMLElement = evt.target as HTMLElement
        if (target.dataset.id) {
          const el = document.getElementById(target.dataset.id)
          if (el && offcanvas) {
            el.classList.add('showing')
            el.classList.add('active')
            setTimeout(() => {
              offcanvas.classList.add('darken')
              el.classList.remove('showing')

              const currentElement = document.querySelector('.front-matter-canvas.active')
              if (currentElement) {
                offcanvas.setAttribute('data-last-resource', currentElement.id)
              }
            }, 100)

            onClickOutside(el, offcanvas)
          }
        }
      })
    })
  })

  observeScriptureIntersection()
}

function onClickOutside(element: Element, parent: Element) {
  document.addEventListener('click', (event: Event) => {
    if (!element.classList.contains('active')) {
      return
    }

    if (!element.contains(event.target as Node) && !element.classList.contains('showing')) {
      element.classList.add('hiding')
      parent.classList.remove('darken')

      setTimeout(() => {
        element.classList.remove('active')
        element.classList.remove('hiding')
      }, 250)
    }
  })
}