export const observeScriptureIntersection = () => {
  const sections = document.querySelectorAll('.scripture__body')

  const intersectionCallback: IntersectionObserverCallback = (entries) => {
    entries.forEach(entry => {
      const isVisible = entry.isIntersecting
      const index = entry.target.getAttribute('data-index')
      const targetButton = document.querySelector(`.mobile[data-bs-target="#front-matter-offcanvas-${index}"]`)

      if (targetButton) {
        if (isVisible) {
          const otherButtons = document.querySelectorAll('.show-mobile-front-matter')
          otherButtons.forEach(button => {
            if (button !== targetButton) {
              button.classList.remove('show-mobile-front-matter')
            }
          })

          setTimeout(() => {
            targetButton.classList.add('show-mobile-front-matter')
          }, 300)
        } else {
          targetButton.classList.remove('show-mobile-front-matter')
        }
      }
    })
  }

  const observer = new IntersectionObserver(intersectionCallback, { threshold: 0.1 })

  sections.forEach((section, index) => {
    section.setAttribute('data-index', index.toString())
    observer.observe(section)
  })
}