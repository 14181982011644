
export function threddedInit() {
  initCommentsBox()

  $(document).on('render_async_load', function(_event) {
    initCommentsBox()
  })
}

declare global {
  interface Window {
    ThreddedPreviewArea: new (form: HTMLFormElement, textarea: HTMLTextAreaElement) => unknown,
    autosize: (elem: HTMLElement) => void

    initPostForm: (form: HTMLFormElement) => void
  }
}

// copied from thredded/components/post_form.es6
const COMPONENT_SELECTOR = '[data-thredded-post-form]'
const CONTENT_TEXTAREA_SELECTOR = 'textarea[name$="[content]"]'
window.initPostForm = (form: HTMLFormElement) => {
  const $form = $(form)
  if ($form.attr('data-remote')) {
    // This one is already initialized
    return
  }

  const textarea = form.querySelector(CONTENT_TEXTAREA_SELECTOR) as HTMLTextAreaElement
  if (textarea) {
    window.autosize(textarea)
    new window.ThreddedPreviewArea(form, textarea)
  }
  // ThreddedMentionAutocompletion.init(form, textarea);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleError(_event: any, _status: any, _xhr: any) {
    // We only get here if something goes wrong on our end.  There's no validation
    // on comments.
    $form.append(
      $('<div>').text('An unexpected error occurred!  Please try again later.')
        .addClass('errors'),
    )
  }

  $form.on('ajax:error', (event, xhr, status) => {
    handleError(event, status, xhr)
  })

  $form.on('ajax:before', (_event, _xhr, _status) => {
    $form.find('.errors').remove()
  })

  $form.attr('data-remote', 'true')
}

function initCommentsBox() {

  // custom code
  $(COMPONENT_SELECTOR).each((_, form) => {
    // We have to initialize this ourselves since Thredded.onPageLoad is not called
    // during render_async, and there's no way to invoke it.
    window.initPostForm(form as HTMLFormElement)
  })
}


