export function flashNotice() {
  $(() => {
    const $flash = $('#flash-notice')

    if (document.cookie.split(';').some((item) => item.trim().startsWith('flash='))) {
      const cookieValue = JSON.parse(decodeURIComponent(getCookie('flash')))

      for (const key in cookieValue) {
        $flash.append(
          $('<div>').text(cookieValue[key].replace(/\+/g,' ')).addClass('alert alert-light').delay(5000).fadeOut()
        )
      }

      document.cookie = 'flash= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    }
  })
}

interface ICookie {
  [key: string]: string;
}

function getCookie(cookieName: string) {
  const cookie: ICookie = {}
  document.cookie.split(';').forEach(function(el) {
    const [key,value] = el.split('=')
    cookie[key.trim()] = value
  })
  return cookie[cookieName]
}