export function studyNotesInit() {
  const studyNotes = document.querySelectorAll('.study-note')
  const studyNoteButtons = document.querySelectorAll('.study-note-button')

  if(studyNoteButtons.length === 0 && studyNotes.length === 0) {
    return
  }

  studyNotes.forEach((note) => {
    if(window.innerWidth >= 1200) {
      note.setAttribute('data-bs-parent', '#scripture-accordion')
    }
  })

  studyNoteButtons.forEach((button, index) => {
    button.classList.add('accordion-button')
    button.setAttribute('data-bs-toggle', 'collapse')
    button.setAttribute('aria-expanded', 'false')
    button.insertAdjacentElement('afterend', studyNotes.item(index))
  })

  const images = document.querySelectorAll('.study-note-content.diagram, .study-note-content.map, .study-note-content.chart , .study-note-content.illustration')

  images.forEach(image => {
    const modalHtml = `
      <div class="modal fade resource" id="${image.id}-modal" tabindex="-1" aria-labelledby="resourceLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">${image.getAttribute('type') || 'Image'}</h5>
              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                <i class='material-icons'>close</i>
              </button>
            </div>
            <div class="modal-body">
              ${image.outerHTML}
            </div>
          </div>
        </div>
      </div>
    `

    const canvasHtml = `
      <div class="offcanvas offcanvas-bottom resource" tabindex="-1" id="${image.id}-offcanvas" aria-labelledby="offcanvasLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasLabel">${image.getAttribute('type') || 'Image'}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          ${image.outerHTML}
        </div>
      </div>
    `

    const modalFragment = document.createRange().createContextualFragment(modalHtml)
    const canvasFragment = document.createRange().createContextualFragment(canvasHtml)

    if (modalFragment.firstElementChild) {
      document.body.appendChild(modalFragment.firstElementChild)
    }

    if (canvasFragment.firstElementChild) {
      document.body.appendChild(canvasFragment.firstElementChild)

      const canvas = document.getElementById(`${image.id}-offcanvas`)
      canvas?.style.setProperty('--bs-offcanvas-height', canvas.offsetHeight + 'px')
    }
  })
}
