export function audioInit() {
  $(() => {
    $('[data-audio-button').each((_, input) => {
      const $button = $(input)

      function handleButton(id: string) {
        const audio = document.getElementById(id) as HTMLAudioElement | null

        if(audio && audio.paused) {
          audio.style.display = 'block'
          audio.play()
        } else if(audio && $(audio).is(':visible')) {
          audio.pause()
          audio.style.display = 'none'
        }
      }

      $button.on('click', () => {
        handleButton($button.data('audio-button'))
      })
    })
  })
}